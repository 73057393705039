import { SubmitHandler } from 'react-hook-form';
import * as Yup from 'yup';

import { Colors } from '../../../styles/colors';

import { Form } from '../../atoms/Form/Form';
import { PrimaryButton, SecondaryButton, WrapperButtons } from '../../atoms/Button/Buttons';
import { H2 } from '../../atoms/Typography/Headings';
import { AlertType, InfoAlert } from '../../atoms/InfoAlert';
import { AlertIcon, ChevronRightIcon } from '../../atoms/Icons';

export const ConfirmDeleteModal = ({ title, children, handleCancel, handleSubmit }: ConfirmDeleteModalProps) => {
  const onSubmit: SubmitHandler<FormData> = async (data) => {
    handleSubmit(data);
  };

  return (
    <>
      <H2>{title}</H2>
      {children && (
        <InfoAlert icon={<AlertIcon size={3} />} type={AlertType.Warning}>
          {children}
        </InfoAlert>
      )}
      <Form onSubmit={onSubmit} validationSchema={validationSchema}>
        <WrapperButtons>
          <SecondaryButton inline onClick={handleCancel}>
            Cancel
          </SecondaryButton>

          <PrimaryButton type="submit" inline={true} iconRight={<ChevronRightIcon size={1} color={Colors.White} />}>
            Confirm
          </PrimaryButton>
        </WrapperButtons>
      </Form>
    </>
  );
};

const validationSchema = Yup.object().shape({});

type FormData = Yup.InferType<typeof validationSchema>;

type ConfirmDeleteModalProps = {
  children?: React.ReactNode;
  title: string;
  handleSubmit: (a: any) => void;
  handleCancel: (a: any) => void;
};
